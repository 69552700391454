import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { toCssPrefix, Image, breakpoints, hexToRGBA, Button } from '@ecommerce/shared'
import { Recipe } from '../../../graphql/contentfulTypes'
import useDrag from '../../../hooks/useDrag'

const { cssPrefix } = toCssPrefix('RecipeSlide__')

type Props = React.HTMLAttributes<HTMLDivElement> & {
  data: {
    title: Recipe['sliderTitle']
    description: Recipe['sliderDescription']
    image: string
    slug: Recipe['slug']
    backgroundColorCard?: Recipe['backgroundColorCard']
    textColorCard?: Recipe['textColorCard']
  }
  themeId?: string
}

const Wrapper = styled.div<{
  backgroundColorCard?: Recipe['backgroundColorCard']
  textColorCard?: Recipe['textColorCard']
}>`
  min-width: 320px;
  z-index: 0;
  position: relative;
  color: ${({ textColorCard, theme }) => textColorCard || theme.colors.brandText.default};
  font-weight: bold;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding-inline: 16px;
  padding-block-end: 32px;
  background: transparent;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 261px;
    z-index: -1;
    background: ${({ backgroundColorCard, theme }) => backgroundColorCard || theme.colors.black};
    border-radius: 12px;
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    min-width: 343px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
`

const StyledImage = styled(Image)`
  width: 200px;
  height: 200px;
  object-fit: cover;

  @media screen and (${breakpoints.desktop.min}) {
    width: 260px;
    height: 260px;
  }
`

const ContainerBottom = styled.div`
  display: grid;
  place-items: center;
  gap: 16px;
`

const Title = styled.h2`
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-block: 0;
`

const StyledButton = styled(Button)`
  padding-inline: 35px;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.24px;
`

const RecipeSlide = ({ data, className = '', themeId, ...restProps }: Props) => {
  const { title, description, image, slug } = data
  const { onMouseDown, onMouseUp, onTouchStart } = useDrag()
  const onClick = () => navigate(slug, { state: { themeId } })

  return (
    <Wrapper
      {...restProps}
      onMouseDown={onMouseDown}
      onMouseUp={(e) => onMouseUp(e, onClick)}
      onTouchStart={onTouchStart}
    >
      <Container>
        <StyledImage src={image} alt={title} />
        <ContainerBottom>
          <Title>{title}</Title>
          <StyledButton color="primary" type="button">
            Ver receta
          </StyledButton>
        </ContainerBottom>
      </Container>
    </Wrapper>
  )
}

export default RecipeSlide
