import { css } from 'styled-components'

export const backgroundImageStyles = <T extends { backgroundImage?: string }>() => ({ backgroundImage }: T) =>
  backgroundImage
    ? css`
        background-image: url(${backgroundImage});
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
      `
    : ''
