import React, { useEffect } from 'react'
import { PageProps } from 'gatsby'
import { toCssPrefix, breakpoints, useShoppingCart, useAuth } from '@ecommerce/shared'
import styled, { ThemeProvider } from 'styled-components'
import Layout from '../../components/Layout'
import { PageCommonData, FlatLocationProductCategory } from '../../types/PgPages'
import useTheme from '../../hooks/useTheme'
import { Recipe, Block, BlockType } from '../../graphql/contentfulTypes'
import SectionTitle from '../../components/Moments/SectionTitle'
import RecipeSlide from '../../components/Recipes/Slide'
import Slider from '../../components/FramerSlider'
import Breadcrumbs from '../../components/Breadcrumbs'
import Seo from '../../components/Seo'
import { backgroundImageStyles } from '../../utils/styles'
import { sendPageViewEvent } from '../../utils/events'

type Props = PageProps<
  {},
  {
    data: PageCommonData & { blocks: Block<Recipe>[] }
    productCategories: FlatLocationProductCategory[]
  }
>

const { cssPrefix, toPrefix } = toCssPrefix('AllRecipesPage__')

const Wrapper = styled.div<{ backgroundImage?: string }>`
  padding: 32px 0 160px 0;
  min-height: calc(100vh - 160px);
  background: ${({ theme }) => theme.colors.background.bodyAlternative};
  color: ${({ theme }) => theme.colors.bodyText};
  ${backgroundImageStyles()}

  .${cssPrefix} {
    &inner {
      max-width: 1550px;
      margin: auto;
    }

    &recipes-list {
      padding-left: 22px;
    }

    &recipe-slide {
      margin-right: 20px;
    }

    &breadcrumbs {
      display: none;
      background: inherit;

      span {
        &:nth-child(3) {
          span {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding: 32px 80px 200px;
    min-height: calc(100vh - 200px);

    .${cssPrefix} {
      &breadcrumbs {
        display: flex;
        padding-left: 0;
        margin-bottom: -45px;
        position: relative;
        z-index: 1;
      }

      &list-title {
        margin-top: 0;
        margin-bottom: 60px;
      }
    }
  }
`

const AllRecipes = ({ pageContext: { data, productCategories = [] } }: Props) => {
  const { title, keywords, description, slug: pageSlug, navbar, theme, blocks } = data
  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Todas las recetas' })
  }, [])

  const { mergeThemes, bodyBackgroundImage } = useTheme(theme?.contentful_id)

  const [, momentSlug] = pageSlug.split('/')
  const breadcrumbs = [
    {
      name: 'Inicio',
      url: `/`,
    },
    {
      name: 'Categorías',
      url: `/category`,
    },
    {
      name: `${momentSlug.replace('-', ' ')}`,
      url: `/momento/${momentSlug}`,
    },
    {
      name: 'Recetas',
      url: `/${pageSlug}`,
    },
  ]

  return (
    <ThemeProvider theme={mergeThemes}>
      <Layout categories={productCategories} navbarType={navbar?.type} navbarTitlte={navbar?.title} title={title}>
        <Seo keywords={keywords} description={description} />
        <Wrapper backgroundImage={bodyBackgroundImage} className={cssPrefix}>
          <div className={toPrefix('inner')}>
            <div className={toPrefix('navigation')}>
              <Breadcrumbs className={toPrefix('breadcrumbs')} items={breadcrumbs} />
            </div>
            {blocks?.map((block) => {
              switch (block.blockType) {
                case BlockType.RECIPE: {
                  const items = block.items ?? []
                  return (
                    <div className={toPrefix('recipes-list')}>
                      {block?.title && (
                        <SectionTitle
                          color={block.titleColor}
                          className={toPrefix('list-title')}
                          text={block.title}
                          {...block}
                        />
                      )}
                      <Slider itemsCount={items.length}>
                        {items.map((slide) => (
                          <RecipeSlide
                            className={toPrefix('recipe-slide')}
                            key={slide.id}
                            data={{
                              title: slide.sliderTitle,
                              description: slide.sliderDescription,
                              image: slide.sliderImage.file.url,
                              slug: `/recetas/${slide.slug}`,
                            }}
                          />
                        ))}
                      </Slider>
                    </div>
                  )
                }

                default:
                  return null
              }
            })}
          </div>
        </Wrapper>
      </Layout>
    </ThemeProvider>
  )
}

export default AllRecipes
