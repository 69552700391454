import React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
  title?: string
  keywords?: string[]
  description?: string
  children?: React.ReactNode
}

const Seo = ({ title, keywords, description, children = null }: Props) => (
  <Helmet>
    {title && <title>{title}</title>}
    {keywords && keywords.length !== 0 ? <meta name="keywords" content={keywords.join()} /> : null}
    {description && <meta name="description" content={description} />}
    {children}
  </Helmet>
)

export default Seo
