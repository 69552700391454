import { useState } from 'react'
import { dragHandler } from '@ecommerce/shared'

const useDrag = () => {
  const { initialDragState, startDragCheck, endDragCheck } = dragHandler
  const [dragState, setDragState] = useState<dragHandler.DragState>(initialDragState)

  const onTouchStart = (e: React.TouchEvent) => e.preventDefault()
  const onMouseDown = (e: React.MouseEvent) => setDragState(startDragCheck(e))
  const onMouseUp = (e: React.MouseEvent, func?: () => void) => {
    setDragState(
      endDragCheck(e, dragState, () => {
        if (func) func()
      }),
    )
  }

  return { dragState, onMouseDown, onMouseUp, onTouchStart }
}

export default useDrag
